// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { hideVisually } from 'polished';
import SEO from '@src/components/SEO';
import Layout from '../components/Layout';

// styles
import { magnifyStyle } from '../styles/global';

// views
import HeroSection from '../views/ConsumersPageView/HeroSection';
import IntroSection from '../views/ConsumersPageView/IntroSection';
import ProductsSection from '../views/ConsumersPageView/ProductsSection';
import RetailersSection from '../views/ConsumersPageView/RetailersSection';

const ParticuliersPage = ({ data }) => {
  const {
    products: { nodes: products },
    categories: { nodes: categories },
    retailers: { nodes: retailers },
  } = data;

  const langLinks = [{ langKey: 'en', href: '/en/consumers' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO
        langLinks={langLinks}
        title='Particuliers'
        description='Besoin de solutions de rangement domestique durables et de première qualité? Les produits Permafil sont robustes, polyvalents et s’adaptent à votre espace.'
      />

      <HeroSection>
        <h1>
          Particuliers<span css={hideVisually()}> - </span>
          <small>Produits de rangement pour votre quotidien</small>
        </h1>
      </HeroSection>

      <IntroSection>
        <h2 css={hideVisually}>Pourquoi choisir Permafil</h2>

        <p css={magnifyStyle}>
          Depuis 1986, Permafil se spécialise dans la conception et
          l’installation de produits de rangement pour la maison.
        </p>

        <p>
          Notre réputation n’est d’ailleurs plus à faire : nos produits sont
          reconnus à travers le continent pour leur qualité et leur durabilité.
        </p>

        <p>
          Permafil, c’est une gamme de produits de rangement domestique
          fièrement fabriqués au Québec, à notre usine de Sainte-Marguerite en
          Beauce, que vous pouvez vous procurer chez plusieurs grands
          quincaillers à travers la province.
        </p>
      </IntroSection>

      <ProductsSection products={[...categories, ...products]} />

      <RetailersSection retailers={retailers} />
    </Layout>
  );
};

ParticuliersPage.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
    retailers: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
};

export default ParticuliersPage;

export const query = graphql`
  query particuliersPageQuery {
    products: allSanityProduct(
      filter: {
        targetAudience: { eq: "particular" }
        localization: { locale: { eq: "fr" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        title
        categories {
          id
        }
        localization {
          locale
        }
        models {
          name
          pictures {
            hotspot {
              x
              y
            }
            asset {
              fluid(maxWidth: 416, maxHeight: 318) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }

    categories: allSanityProductCategory(
      filter: {
        targetAudience: { eq: "particular" }
        localization: { locale: { eq: "fr" } }
      }
      sort: { fields: sortOrder, order: ASC }
    ) {
      nodes {
        _type
        id
        slug {
          current
        }
        title
        targetAudience
        pictures {
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 664, maxHeight: 408) {
              ...GatsbySanityImageFluid
            }
          }
          alt
        }
        localization {
          locale
        }
      }
    }

    retailers: allSanityRetailer(
      filter: { localization: { locale: { eq: "fr" } } }
    ) {
      nodes {
        name
        url
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`;
